<template>
  <common-page-edit 
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :paramsSend="{
          entity: nameEnity
        }"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    routeNameShow="EntityDynamicShow"
    routeNameCreate="CreatePageDynamic"
    :backUrl="'/entity/' + $route.params.entity"
  />
</template>
<script>
import { mapState } from "vuex";
import { TYPE_ERRORS, FIELD_LANG_ENTITY, API_METHODS } from "../../../../constant";
import {NAME_OBJECT, LIST_EQUIVALENT} from "../../../../RoleConstanans"
import CommonPageEdit from '../../commonElement/CommonPageEdit.vue';

export default {
  name: "EditPageDynamic",
  components: {
    CommonPageEdit
  },
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,
      routeNameShow: "EntityDynamicShow",
      routeNameCreate: "CreatePageDynamic",
      apiNameGet: API_METHODS.GET_ENTITY,
      apiNameCreate:API_METHODS.UPDATE_ENTITY,
      apiNameUpdate:API_METHODS.UPDATE_ENTITY
    };
  },
  computed: {
    nameEnity(){
      return this.$route.params.entity
    },
    ...mapState({
      listEntity: state => state.entity.listEntity
    }),
    title() {
      let find = this.listEntity.find(
        el => el.code == this.$route.params.entity
      );
      let res = find ? find.name : "";
      let text1 = this.$t("Просмотр элемента справочника") + res;
      let text2 = this.$t("Добавление элемента справочника") + res;
      let text3 = this.$t("Редактирование элемента справочника") + res;

      let findShow = this.$route.path.indexOf('show')
      if(findShow !=-1) {
        return text1
      }
      let findCreate = this.$route.path.indexOf('create')
      if(findCreate !=-1) {
        return text2
      }
      let findEdit = this.$route.path.indexOf('edit')
      if(findEdit !=-1) {
        return text3
      }
      return ""
    },
    nameRoleEntity() {
      let res = null
      const nameEntity  = this.$route.params.entity
      const listLoadEntity = this.$store.state.entity.listLoadEntity
      let find = listLoadEntity.find(el=> {
         let _find = el.list.find(_el=>_el.code == nameEntity)
         return _find
      })
      res = find && find.parent?find.parent.code:nameEntity
      const userRoleData = this.$store.state.common.userRoleData
      const name = LIST_EQUIVALENT[res] ? LIST_EQUIVALENT[res] : res;
      if(userRoleData[name]){
          return name
      } else {
          return NAME_OBJECT.Reference 
      }
    }
  }
};
</script>
